.NewCompositionModal__options {
  display: flex;
}

.NewCompositionModal__button-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 3rem;
  grid-column-gap: 5px;

  button {
    font-size: 14px;
    justify-content: center;
    padding: 7px 7px;
  }

}

.NewCompositionModal__name-input {
  color: #fff;
  padding: 0 0.5rem;
  font-size: 1rem;
  width: 100%;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.025);
  border-radius: 4px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.5);

  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
}
