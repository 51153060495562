.DesignTab {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  // heading section
  padding-bottom: 3rem;
  .DesignTab__heading--section {
    padding: 0.75rem;

    .DesignTab__heading {
      color: #ffffff;
      font-size: 1rem;
      line-height: 1.75rem;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
}

.DesignTab__heading__mute {
  position: absolute;
  top: 0.3rem;
  left: 23rem;
  &:active,
  &:focus {
    outline: none;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: black;
  }
}

.DesignTab .Designs__AspectRadio___filter {
  margin: 0 0 0.5rem 0.8rem;
  display: flex;
  max-width: 22rem;
  .ButtonGroup__item {
    // flex: none;
    .ButtonGroup__item--selected {
      background-color: red !important;
    }
  }
}

.DesignTab .masonry-tile {
  column-count: 5;
  padding: 0.9rem 0 0 0.8rem;
  .Thumbnail_container {
    position: relative;
  }
  button {
    position: absolute;
    bottom: 2px;
    color: black;
    z-index: 999999;
    background: white;
    opacity: 0.5;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.DesignTab .scroll-more {
  position: absolute;
  left: 0px;
  right: 6px;
  bottom: -1px;
  border-radius: 0;
  height: 28px;
  transition: 1s opacity ease;
  //background: linear-gradient(0deg, #ccc 0%, rgba(244, 244, 244, 0) 100%);
  box-shadow: none;
  pointer-events: none;
}
