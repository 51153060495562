.TrackRow {
  padding: .7rem;
  background-color: var(--color4);
  border-radius: .25rem;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-shrink: 0;
  border: 1px solid rgba(35, 47, 53, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@keyframes example {
  0% {
    background-color: var(--color1);
  }

  50% {
    background-color: #f8a8af;
  }

  100% {
    background-color: var(--color1);
  }
}

.TrackRow--active {
  // animation-name: example;
  // animation-duration: 1s;
  // animation-timing-function: ease;
  // background-color: var(--color1);
  // color: white;

  // svg {
  //   fill: white;
  // }
}

.TrackRow__thumbnail {
  margin: .375rem;
  height: 2.5rem;
  width: 4rem;
  background: #eee;
  border-radius: .125rem;
}

.TrackRow__content {
  max-width: 50%;
  flex: 1;
  margin: .375rem;
  display: flex;
  flex-direction: column;
  line-height: 1.25rem;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  width: 50%;

  .TrackRow__content--name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12rem;
    color: var(--color2)
  }
}

.TrackRow__duration {
  font-size: .875rem;
  color: #eee;
  line-height: 1.25rem;
  font-weight: 500;
}

.TrackRow__action {
  display: flex;
  background-color: rgba(255, 255, 255, 0.025);
  border-radius: .125rem;
  padding: .5rem;
  margin: .375rem;
}

.TrackRow__button-wrapper {
  margin: .375rem;
  display: flex;
  width: 127px;
  justify-content: flex-end;

  .Button {
    &:nth-child(1) {
      background-color: #c3ced7 !important;

      svg {
        fill: #111 !important;
      }
    }

    &:last-child {
      margin-left: .375rem;
    }
  }
}