.Tab__section {
  padding: .75rem;
}

.Tab__heading {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
  flex: 1 1 0%;
}
