.VideoPreview {
  width: 100%;
  height: 100%;
  margin: 0 .5rem;
  position: relative;
}

.previewContainer {
  width: 1920px;
  height: 1080px;
  position: relative;
  position: absolute;
  top: 30px;
  left: 20px;
  overflow: hidden;
}

.VideoPreview--hidden {
  display: none;
}

.VideoPreview--hovered {
  .upper-canvas {
    box-shadow: 0 0 0 0.125rem var(--color1);
  }
}

.VideoPreview--highlighted {
  .upper-canvas {
    background-color: rgba(105, 100, 115, 1);
  }
}

.VideoPreview__video-text-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
}

.VideoPreview__video {
  border-radius: .125rem;
  align-self: start;
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  display: block;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
}

.VideoPreview__pauseOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.VideoPreview__Guides {
  position: fixed;
  background-color: #2ebf91;
  opacity: 0.5;
  z-index: 1;
}

.VideoPreview__xGuide {
  height: 2px;
  width: 100%;
}

.VideoPreview__yGuide {
  width: 2px;
}

.free-forever {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  // font-family: "Quicksand", sans-serif;
}

.free-forever--real {
  font-size: 5rem;
  line-height: 5rem;
  color: #ffffff;
}

.text-box {
  display: flex;
  cursor: default;
  text-decoration: none;
}

.text-box:hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.centered {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.canvas-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #canvas {
    background-color: #000;
  }
}

.canvas-container canvas {
  border-radius: .125rem;
  align-self: center;
  max-width: 100%;
  max-height: 100%;
}

#canvas {
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
}

.ShortcutsModal {
  font-size: 14px;
  display: block;
  padding-bottom: .5rem;
}

.Modal__content ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 30px;

  li {
    padding: 5px;
  }
}


.ShortcutsModal__button-container {
  display: flex;
  padding-top: 1rem;
  justify-content: flex-end;
}