@import '@styles/_colors.scss';

.NavigationItem {
  padding: .5rem .4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);

    .NavigationItem__icon {
      opacity: 1;
    }
  }
}

.NavigationItem:nth-child(3) {
  border-right: 1px dashed #494555;
}

.NavigationItem__label {
  font-size: 12px;
  line-height: .7rem;
  padding-bottom: 2px;
  color: var(--color2);
  font-weight: 600;
  text-decoration: none;
  display: block;
}


.NavigationItem--selected {
  background-color: rgba(255, 255, 255, 0.05);

  .NavigationItem__icon * {
    opacity: 1;
    fill: $icon-active;
  }
}

.NavigationItem--disabled {
  cursor: not-allowed;

  .NavigationItem__icon * {
    fill: #5d5d5d;
    pointer-events: none;
  }

  &:hover {
    .NavigationItem__icon {
      opacity: 0.8;
    }
  }
}

.NavigationItem--selected .NavigationItem__icon {
  opacity: 1;
}