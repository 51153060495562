.Scrubber {
  width: 100%;
  height: .25rem;
  border-radius: .125rem;
  background: var(--color1);
  display: flex;
  position: relative;
  margin-bottom: .25rem;
  cursor: pointer;
}

.Scrubber__Progress {
  display: flex;
  justify-content: flex-end;
  background-color: var(--color1);
  border-radius: .0125rem;
}

.PlayHead__Controls {
  position: absolute;

  .PlayHead__Handle {
    position: absolute;
    height: .7rem;
    width: .7rem;
    border-radius: 50%;
    background-color: var(--color1);
    margin-top: -.315rem;
    margin-left: -4px;
    cursor: pointer;
    z-index: 4;
    border: 1px solid var(--color1);

    &:hover,
    &:active {
      transform: scale(1.25);
    }
  }
}


.PlayHead__Line {
  position: absolute;
  height: 188px;
  width: 1px;
  background-color: var(--color1);
  z-index: 3;
  pointer-events: none;
}