.backgroundColorLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.htmlTextInstance_controls {
  pointer-events: all;
  .resizer-br {
    position: absolute;
    right: -10px;
    bottom: -10px;
    width: 20px;
    height: 20px;
    background: white;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    transform: rotate(45deg);
    border-radius: 50%;
    cursor: se-resize;
  }
  .resizer-bl {
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    background: white;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    transform: rotate(45deg);
    border-radius: 50%;
    cursor: sw-resize;
  }
  .resizer-tr {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background: white;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    transform: rotate(45deg);
    border-radius: 50%;
    cursor: ne-resize;
  }
  .resizer-tl {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    background: white;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    transform: rotate(45deg);
    border-radius: 50%;
    cursor: nw-resize;
  }
  .resizerH-right {
    position: absolute;
    right: -4px;
    top: calc(50% - 25px);
    width: 10px;
    height: 40px;
    background: white;
    border-radius: .2em;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    cursor: ew-resize;
  }

  .resizerH-left {
    position: absolute;
    // right: 900px;
    left: -4px;
    top: calc(50% - 25px);
    width: 10px;
    border-radius: .2em;
    height: 40px;
    background: white;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    cursor: ew-resize;
  }

  .resizerV {
    position: absolute;
    right: calc(50% - 25px);
    bottom: -14px;
    width: 50px;
    height: 20px;
    background: white;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    cursor: ns-resize;
  }
  .rotateHandle {
    position: absolute;
    right: calc(50% - 25px);
    bottom: -140px;
    background: white;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    text-align: center;
    padding-top: 5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: ew-resize;


    svg {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 40px;
      height: 40px;
      pointer-events: none;
     }
  }
  .rotateCounter {
    position: absolute;
    right: calc(50% + 35px);
    font-size: 20px;
    text-align: center;
    padding-top: 7px;
    bottom: -120px;
    width: 55px;
    height: 37px;
    background-color: white;
    border-radius: 5%;
    cursor: ew-resize;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    span {
      position: relative;
      bottom: 60px;
      color: #111;
     }
  }
}
