.ProjectActionsMenu {
  width: 70px;
  top: -12px;
  left: -25px;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  position: absolute;
  color: #111;
  // top: 40px;
  right: 0;
  z-index: 1;
  box-shadow: -2px 2px 2px #f3f3f3;
  border-radius: 0em 0em 0em .5em;
}

.ProjectActionsMenu__option {
  padding: 9px;
  width: 100%;
  font-size: 12px;
  text-align: left;

  &:hover {
    background-color: #c62e2d;
    color: white;
    cursor: pointer;
  }

  &:not(:first-child) {
    border-top: 1px solid #f3f3f3;
  }

  &:last-child {
    border-radius: 0em 0em 0em .5em;
  }
}