.content-grid {
  display: flex;
  flex-wrap: wrap;
  padding: .5rem;
  flex: 1;
  align-content: flex-start;
}

.content-grid-col {
  padding: .4rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: .125rem;
  cursor: pointer;
}

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: .5rem;
}

.grid__col {
  padding: .5rem;
}

.w-100 {
  width: 100%;
}

@media only screen and (min-width: 50em) {
  .grid--50 {
    max-width: 50rem;
  }

  .grid--65 {
    max-width: 65rem;
  }

  .grid--75 {
    max-width: 75rem;
  }

  .w-50-m {
    width: 50%;
  }

  .w-33-m {
    width: 33.333%;
  }

  .w-25-m {
    width: 25%;
  }

  .grid,
  .grid__col {
    padding: 1rem;
  }
}

.scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 100px;
  height: 100% !important;
  background-color: var(--color5);

  // &::-webkit-scrollbar {
  //   width: 6px !important;
  //   height: 6px !important
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #2c207f;
  // }

  // &::-webkit-scrollbar-track {
  //   background: white;
  // }
}