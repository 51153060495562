.ProjectCard {
    position: relative;
    width: 100%;
    background-size: cover;
    color: white;
    border-radius: .45rem;
    box-shadow: 1px 1px 4px #c9c9c9;
    &:hover {
        .ProjectActions {
            display: flex;
        }
    }
}

.ProjectCard__content {
    position: relative;
    height: 100%;
    // Broke with newest Chrome (08/20), is this needed? Replaced with height: 100%
    // padding-bottom: calc(100% * 9 / 16);
}

.ProjectCard__content > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background: rgba(58, 123, 213, 0.5);  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top right, rgba(58, 123, 213, 0.75), rgba(0, 210, 255, 0.75));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top right, rgba(58, 123, 213, 0.75), rgba(0, 210, 255, 0.75)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: .125rem;

}

.ProjectCard:hover .ProjectCard__content > div {
  background: rgba(58, 123, 213, 0.8);  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top right, rgba(58, 123, 213, 0.8), rgba(0, 210, 255, 0.8));  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top right, rgba(58, 123, 213, 0.8), rgba(0, 210, 255, 0.8)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.ProjectCard__project-name{white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: .823rem;color: #ffffff; font-weight: 500; line-height: 1.5rem;  }
.ProjectCard__versions-amount{color: #f5f5f5; font-weight: 500; line-height: 1.5rem; font-size: .875rem; }

.ProjectCard__videoThumbnails {
    display: flex;
    width: 100%;
    height: 102px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;

    .thumb {
        width: 33%;
        height: auto;
        display: flex;
        flex-shrink: 0;
        margin: 1px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .thumb--landscape {
        width: 170px;
        height: 100px;
    }

    .thumb--square {
        width: 100px;
        height: 100px;
    }

    .thumb--vertical {
        width: 58px;
        height: 100px;
    }
}

.ProjectCard__videoThumbnails:before {
    content:'';
    width: 100%;
    height: 100%;
    position: absolute;
    background:linear-gradient(90deg, transparent 97.5%, white);
}
