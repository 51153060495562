.PositionGrid {
  position: absolute;
  left: 263px;
  display: flex;
  flex-wrap: wrap;
  margin: .375rem;
  width: 4.25rem;
}

.PositionGrid--active {
	border: 1px solid #18e3ff;
}
