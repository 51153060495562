@import '@styles/_base.scss';
@import '@styles/_grid.scss';
@import '@styles/_colors.scss';

.Sidebar {
  position: relative;
  width: 33rem;
  overflow: hidden;
  height: 100%;
  margin: 0px;
  // box-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0 0 0.125rem 0.125rem;
}

.Sidebar--expanded {
  min-width: 75%;

  .content-grid-col {
    // padding: 0.4rem;
    margin-bottom: 60px;
    max-width: 33%;
  }
}

.Sidebar--fullWidth {
  .Sidebar__nav {
    padding-top: 15px;
    display: none;
  }

  .Sidebar__content {
    color: var(--color2);
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .content-grid-col {
    width: 33.3%;
  }

  .ProjectCard {
    height: 180px;
  }

  .ProjectCard__content {
    padding-bottom: unset;
  }

  .ProjectCard__content>div {
    height: -webkit-fill-available;
  }

  .ProjectsTab {
    width: 100%;
    margin: auto;
    background: white;
  }

  .ContextSettingsGrid__header {
    text-align: left;
    //padding: 10px;
    font-size: 14px;
    font-weight: 400;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  .ProjectsTab__section:nth-child(1) {
    float: left;
  }

  .ProjectsTab__section:nth-child(2) {
    float: right;
    justify-content: flex-end;
  }

  .ProjectList.content-grid.scroll-y {
    clear: both;
    // padding-bottom: 0;
    height: auto;
  }

  .ProjectActions {
    position: absolute;
    right: 5px;
    top: 12px;
    margin: 0;
    background: transparent;
  }

  .scroll-y {
    width: 100%;
    background-color: white;
  }
}

.Sidebar__wrapper {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  height: 100%;

  .expand-collapse {
    position: absolute;
    width: 18px;
    right: -19px;
    height: 48px;
    top: 50%;
    cursor: pointer;
    background: #2c217f;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 2;

    svg {
      position: relative;
      top: 11px;
      right: 3px;
      transform: scale(0.7);
    }
  }
}

.Sidebar__content {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
  overflow-y: hidden;
  background-color: var(--color5);

  .ContextSettingsGrid__Row {
    background: var(--color5);
    gap: 1rem;
  }
}

.Sidebar__title {
  display: flex;
  margin-bottom: 10px;
}

// .Sidebar__content::-webkit-scrollbar {
//   width: 6px !important;
//   height: 6px !important;
// }

// .Sidebar__content::-webkit-scrollbar-thumb {
//   background-color: rgba(255, 255, 255, 0.2);
// }

// .Sidebar__content::-webkit-scrollbar-track {
//   background: rgba(255, 255, 255, 0.1);
// }

.input {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  font-weight: 600;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  width: 100%;
  padding: 0.25rem;
  padding-left: 0.5rem;
  border-radius: 0.125rem;
  background-color: rgba(255, 255, 255, 0.1);

  background-image: url("data:image/svg+xml;utf8,<svg fill='#757575' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' /></svg>");
  background-repeat: no-repeat;
  background-position: left 0.5rem center;
}

.content-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  flex: 1;
}

.content-grid-col {
  // padding: 0.25rem;
  width: 50%;
  display: flex;
  flex-direction: column;
}