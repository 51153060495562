.shapeLayer, .imageLayer {
  pointer-events: all;
  cursor: move;
  position: relative;

  .layerContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
  }
  
  .layerContent img {
    pointer-events: none;
  }
  
  .backgroundColor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  
  .controls {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }
  
  .controls .resizeHandle {
    position: absolute;
    right: -10px;
    bottom: -10px;
    width: 20px !important;
    height: 20px !important;
    background: white;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    border-radius: 50%;
    cursor: se-resize;
  }
  .controls .rotateHandle {
    text-align: center;
    padding-top: 5px;
    position: absolute;
    right: 10px;
    bottom: -70px;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    cursor: ew-resize;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);

     
    svg {
      width: 40px;
      height: 40px;
      pointer-events: none;
     }
  }
  .controls .rotateCounter {
    right: 124.8px;
    font-size: 20px;
    text-align: center;
    padding-top: 7px;
    position: absolute;
    right: 10px;
    bottom: -120px;
    width: 55px;
    height: 37px;
    background-color: white;
    border-radius: 5%;
    cursor: ew-resize;
    box-shadow: 0 0 5px 5px rgba(14,19,24,.2);
    svg {
      width: 40px;
      height: 40px;
      pointer-events: none;
     }
  }
}
