.ButtonGroup {
  border: 1px solid var(--color4);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: .25rem;
  width: 100%;
}


.Sidebar--expanded {
  .ButtonGroup {
    border: 1px solid var(--color4);
    align-items: center;
    width: 40%;
    margin-top: .25rem;
    max-height: 31px;
  }
}

.ButtonGroup__header {
  padding: 0;
  width: 13rem;
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.ButtonGroup__header .ButtonGroup__item {
  flex: auto;
}

.ButtonGroup__item:first-child {
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
}

.ButtonGroup__item:last-child {
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
}

.ButtonGroup__item {
  // flex:1;
  margin: .5px;
  padding: 5px 7px 6px 7px;
  background-color: rgba(255, 255, 255, 0.125);
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.35rem;
  display: flex;
  flex-grow: 1;
  cursor: pointer;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ButtonGroup__item:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.ButtonGroup__item--selected {
  color: #07f;
  background-color: var(--color1);
}

.ButtonGroup__item--selected:hover {
  background-color: #fff;
}

.ButtonGroup__item--disabled {
  pointer-events: none;
  opacity: 0.3;
}