.DesignThumbnail {
  position: relative;
  margin-bottom: 1rem;
  display: inline-block;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  border-radius: 0.5rem;
  &:hover {
    cursor: pointer;
    .ProgressBar {
      z-index: 1;
      opacity: 1;
    }
  }

  &:active {
    cursor: -webkit-grabbing;
  }
  &__videoContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    img {
      &:hover {
        // display: none;
      }
    }
    .DesignThumbnail__video {
      z-index: 9999;
    }
  }
}

.DesignThumbnail__video {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.125rem;
}

.DesignThumbnail__delete {
  opacity: 0;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  fill: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    background-color: #ff3805;
  }
}

.DesignThumbnail_name {
  position: absolute;
  top: 0;
  background: white;
  border-bottom-right-radius: 11px;
  font-size: 12px;
  padding: 5px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DesignThumbnail .ProgressBar {
  opacity: 0;
}
