.ConfirmDialog {
  font-size: 14px;
  display: block;
  padding-bottom: .5rem;
}

.ConfirmDialog__button-container {
  display: flex;
  padding-top: 1rem;
  justify-content: flex-end;
}
