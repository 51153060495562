.Search {
  position: relative;
  margin: 12px 0px 5px 0px;
  border-radius: 15px;

  .SortingOptions {
    position: absolute;
    top: 4px;
    right: 50px;
    width: 140px;
    zoom: .70;
    // font-family: "Quicksand", sans-serif;
    color: #111;
  }

  .sortingOptions-select {
    flex: 1 1 0%;
    color: #111;
    border-left: 1px solid #c9c9c9;
    background-color: white;
  }

  .BrowseCategoriesButton {
    position: absolute;
    top: 3px;
    right: 4px;
    cursor: pointer;
  }

  .BrowseCategoriesButton svg {
    fill: #d6d6d9;
  }

  .BrowseCategoriesButton--selected {
    svg {
      fill: var(--color1);
    }
  }

  .react-autosuggest__input {
    border-radius: 4px;
    font-size: .75rem;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
    font-weight: 600;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: none;
    width: 100%;
    padding: .25rem;
    padding-left: .5rem;
    border-radius: .125rem;
    background-color: rgba(255, 255, 255, 0.1);
    background-image: url("data:image/svg+xml;utf8,<svg fill='#757575' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' /></svg>");
    background-repeat: no-repeat;
    background-position: left .5rem center;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
    max-height: 400px;
    overflow-y: auto;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 32px;
    width: 100%;
    background-color: #fff;
    // font-family: "Quicksand", sans-serif;
    font-weight: 300;
    font-size: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 10px;
  }

  .bold {
    font-weight: 800;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: var(--color1);
    color: white;
    font-weight: 400;
  }
}

.Sidebar--expanded {
  .Search {
    width: 40%;
    margin-left: 1rem;
    margin-top: .25rem;
  }
}