.App.light {
	background: var(--color4);
}

.App.light .App__mid-container {
	padding: 0px;
	box-shadow: none;
}

.App.light .Backdrop {
	background-color: rgba(245, 244, 244, 0.5);
}

.App.light .Button {
	border-radius: 3px;
	// Is this needed?
	// padding: .2rem .6rem .2rem .6rem;
	font-size: .8rem;
	align-items: center;
}

.App.light .Button--pink {
	background: var(--color1);
	box-shadow: 0px 1px 3px rgba(128, 128, 128, 0.35);
	border-radius: 3px;
	padding: .2rem .6rem .2rem .6rem;
	font-size: .8rem;
}

.App.light .Button--playing {
	background-color: #c62e2d !important;
	border-radius: .2em !important;

	svg {
		transform: scale(.7);
		fill: white !important;
	}
}

.App.light .ButtonGroup__item {
	// color: var(--color4);
	// background-color: white;
}

.App.light .ButtonGroup__item:hover {
	color: var(--color7);
	background-color: var(--color3); // hsla(151, 95%, 32%, .6);
}

.App.light .ButtonGroup__item--selected {
	color: var(--color7);
	background-color: var(--color1); // #049e53;

	span {
		color: var(--color7)
	}
}

.App.light .ButtonGroup__item--selected:hover {
	background-color: none;
}

.App.light .ProjectsTab .ButtonGroup__item--selected {
	color: #fff;
	background-color: var(--color1);
}


.App.light .DesignTab__heading {
	color: var(--color2) !important;

	svg {
		fill: var(--color2);
	}
}

.App.light .GenerateTab__heading {
	color: var(--color2);
}

.App.light .GenerateTab__RelatedKeywords span {
	color: var(--color2);
}

.App.light .GenerateTab__RelatedKeywords__Item {
	background-color: #999;
}

.App.light .ImagesTab__heading {
	color: var(--color2) !important;
}

.App.light .ShapesTab__heading {
	color: var(--color2) !important;
}

.App.light .Curtain--loading {
	background-color: rgba(0, 0, 0, 0.9);
	filter: invert(1);
}

.App.light .MusicTab__heading {
	color: var(--color2);
}

.App.light .MusicTab__heading__activeTrack span {
	color: var(--color2);
}

.App.light .TrackRow {
	color: var(--color2);
	border: 2px solid transparent;
	// border-top: 1px solid white;
	// border-bottom: 1px solid rgba(35, 47, 53, 0.3);

	button {
		color: var(--color7);
		background-color: var(--color1);

		svg {
			fill: var(--color2);
		}
	}
}

.App.light .TrackRow--active {
	// color: white;
	border: 2px solid var(--color1);
	// position: absolute;
	// width: 335px;
	// padding: .7rem;
	// z-index: 2;
}

.App.light .ProjectCard__content>div {
	background: white;
	border-radius: 7px;
	border: 1px solid white;

	&:before {
		content: none;
	}
}

.App.light .ProjectActions {
	svg {
		fill: var(--color2);
	}
}

.App.light .ProjectCard:hover .ProjectCard__content>div {
	background: white;
	border: 1px solid #c62e2d;

	.ProjectActions {
		display: none;
	}
}

.App.light .ProjectCard__project-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: .823rem;
	color: #1e0949;
	font-weight: 700;
	line-height: 1.5rem;
}

.App.light .ProjectCard__versions-amount {
	color: #1e0949;
	font-weight: 500;
	line-height: 1.5rem;
	font-size: .875rem;
}

.App.light .ProjectsTab__heading {
	color: var(--color2);
}

.App.light .ProjectsTab__new-project-btn {
	width: auto;
}

.App.light .Video__name-input {
	color: var(--color2);

	&:focus {
		border-bottom: 2px solid #ccc;
	}
}

.App.light .SingleProject__back-button {
	color: var(--color2);

	svg {
		fill: var(--color2);
	}
}

.App.light .SingleProject__button {
	width: auto;
	text-align: inherit;
}

.App.light .Video__thumbnail-container {
	background-color: transparent;
	color: var(--color2);

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
		background-color: white;
	}

	&:active,
	&:focus {
		background-color: white;
		border-bottom: 1px solid #ccc;
	}
}

.App.light .VideoActions {
	svg {
		fill: var(--color2);
	}
}

.App.light .react-autosuggest__input {
	color: var(--color2) !important;
	background-color: var(--color4) !important;
}

.App.light .Navigation {
	display: flex;
	background-color: white;
}

.App.light .NavigationItem {
	display: flex;
	flex-grow: 1;
	white-space: nowrap;
}

.App.light .NavigationItem:nth-child(3) {
	border: none;
}

.App.light .NavigationItem__icon {
	// opacity: 0.8;

	svg {
		fill: var(--color2)
	}
}

.App.light .NavigationItem--selected {
	background-color: #c62e2d;

	.NavigationItem__icon * {
		fill: white;
	}

	.NavigationItem__label {
		color: white;
	}
}


.App.light .NavigationItem--disabled {
	.NavigationItem__icon {
		opacity: 0.3;
	}

	// .NavigationItem__icon * {
	// 	fill: #000;
	// }
}

.App.light .Sidebar {
	height: 100%;
	margin: 0px;
	// border-right: 1px solid rgba(0, 0, 0, 0.1);
	// box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
	background: var(--color4);
}

.App.light .Sidebar--expanded {
	.expand-collapse {
		width: 18px;
		right: -19px;
		height: 48px;
		background: #2c217f;
		border-top-right-radius: 7px;
		border-bottom-right-radius: 7px;

		svg {
			position: relative;
			top: 11px;
			right: 3px;
			transform: scale(.7);
		}
	}
}

// .App.light .ContextSettingsStickyBottomBar {
// 	bottom: 199px;
// 	left: 59px;
// 	width: 341px;
//   background: #f3f3f3;
//   border-top: 1px solid #f3f3f3;
// }

.copy-buttons {
	margin-top: 8px;
}

.App.light .ContextSettingsStickyBottomBar__up {
	bottom: 185px;
}

.App.light .SidebarContextOverlay .ContextSettingsGrid__header {
	color: var(--color2);
}

.App.light .ContextSettingsGrid__Item svg {
	transform: scale(.7);
	fill: var(--color2);
}

.App.light .ContextSettingsGrid__Item--Selected {
	background: var(--color1);
}

.App.light .ContextSettingsGrid__Item--Selected svg {
	fill: white !important;
}

// To do in code
// /Sidebar/SidebarVideoOverlay.jsx line 263 coordinates

.App.light .ContextSettingsGrid__Item {
	svg {
		fill: var(--color2);
	}
}

.App.light .ContextSettingsGrid__Item__selected {
	svg {
		fill: var(--color1) !important;
	}
}

.App.light .ContextSettingsGrid__Item-hide {
	color: var(--color2);
}

.App.light .Sidemenu {
	background-color: white;
}

.App.light .Sidemenu__section-title {
	color: var(--color2);
}

.App.light .Sidemenu__section-item {
	color: #1e0949;

	a {
		color: inherit;
	}
}

.App.light .Sidemenu__section-item--active {
	svg {
		fill: #1e0949;
	}
}

.App.light .Sidemenu__logo svg {
	fill: #1e0949;
}

.App.light .Sidemenu__logo-text {
	color: #1e0949;
	background-color: transparent;
}

.App.light .account {
	background: #1e0949;
}

.App.light .account:hover {
	background: #1e0949;
}

.App.light .Tag {
	background-color: rgba(0, 0, 0, 0.4);
}

.App.light .Tag:hover {
	background-color: #cc0000;
	border-color: white;
}

.App.light .TextTab__heading {
	color: var(--color2);
}

.App.light .Timeline {
	background: #201e2c;
}

.App.light .TimeLine__icons {
	display: flex;
	justify-content: center;
	// height: 17px;
}

.App.light .TimeLine__icons--play svg {
	fill: #a7a7a7;
	width: 32px !important;
	height: 32px !important;
}

.intercom-launcher-frame {
	bottom: 35px !important;
	right: 25px !important;
}

.App.light .overlay-resizer {
	box-shadow: 0 0 0 0.205rem var(--color1);

	span div {
		border-color: var(--color1) !important;
	}
}

.App.light .TopHeader {
	height: 62px;
	background-image: linear-gradient(135deg, rgba(245, 51, 109, 1) 0%, rgba(9, 28, 129, 1) 100%);
}

.App.light .TopHeader__group {
	margin-top: 0px;
	margin-bottom: 0px;
}

.App.light .TopHeader__group:nth-child(2) {
	margin-top: 0px;
	margin-bottom: -10px;
}

.App.light .VideoPreview {
	padding: 20px;
}

.App.light #canvas {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.App.light .VideosTab__heading {
	color: var(--color2) !important;
	font-size: 1em;
	font-weight: 400;

	svg {
		z-index: 9999;

		path {
			fill: var(--color2);
		}
	}
}

.App.light .SidebarView__heading {
	color: var(--color2);
}

.App.light .GenerateTab__subheading {
	color: var(--color2);
}

.App.light .ContextSettings__FontSize-select {
	font-size: 12px;
	width: 95%;
	height: 90%;
}

.App.light .ContextSettings__FontSize-control {
	font-size: 100px;
	color: white;
}

.App.light .ContextSettings__TextAnimations__Thumb {
	// filter: invert(1) hue-rotate(180deg);
}

.App.light .ContextSettingsGrid__Item svg {
	fill: var(--color2);
}

.App.light .ColorPicker__current-color {
	border-color: var(--color2);
}

.App.light .Button-Selector__Item {
	height: 38px;
	width: 38px;
	align-items: center;
	justify-content: center;
}

.App.light .Button-Selector__Item--Selected svg {
	fill: white !important;
}

.App.light .Button-Selector__Item--Selected {
	background: var(--color1);
}

.App.light .Button-Selector__Item svg {
	fill: var(--color2);
}

.App.light .ContextSettingsGrid__textShadowControl img {
	filter: invert(1);
}

.App.light .TextTab__style-thumbnail {
	background-color: #b5b5b5 !important;
	border-radius: 4rem;
}

.App.light .Video-Details__Title {
	color: var(--color2)
}

.App.light .Video-Details__Actions .Button--light-bg {
	filter: invert(1);
}

.App.light .Video-Details__Actions button {
	padding: .5rem .75rem;
}

.App.light .theme-switch {
	button {
		width: 30px;
		height: 30px;
		margin: 4px;
		padding: 0 !important;
		background-color: rgba(255, 255, 255, 0.05);

		&:hover {
			background-color: rgb(0, 0, 0);
		}
	}

	svg {
		stroke: #fff;
		width: 30px;
		height: 30px;
	}
}

.App.light .ContextSettings__Adjuster {

	.adjustableOptions {
		background: var(--color5);
		margin: auto;
		margin-bottom: 5px;

		.presetButtonContainer button {
			background: var(--color3);
			color: #fff;
		}
	}

	div[title="#ffffff"] {
		border: 1px solid #ccc;
	}

	div[title="#000000"] {
		border: 1px solid #ccc;
	}

	.advancedButtonContainer {

		button {
			svg {
				fill: var(--color2);
			}
		}

		button.selected {
			background: #c62e2d;

			svg {
				fill: white;
			}
		}
	}
}

.App.light .positionControlsToggleButton {
	path {
		fill: var(--color2);
	}
}

.App.light .lineheightControlsToggleButton {
	svg {
		fill: var(--color2);
	}
}

.App.light .Active-upload {
	display: flex;
	align-items: center;
	justify-content: center;

	.Spinner {
		position: absolute;
		top: calc(50% - 20px);
	}
}