.ImagesTab {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  // heading section
  .ImagesTab__heading--section {
    position: relative;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    background-color: #f3f3f3;
    // box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
    z-index: 5;

    .ImagesTab__heading {
      color: #ffffff;
      font-size: 1rem;
      line-height: 1.75rem;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  .VideosTab__upload-btn {
    width: 100%;
    margin: 10px auto;
    min-height: 30px;
  }

  // image list section

  .ImagesTab__list--section {
    // padding-bottom: 140px;
    height: 100%;

    .ImagesTab__list {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      align-content: flex-start;
      padding: 0.5rem;

      .Active-upload {
        padding-bottom: 56.25%;
        background-color: #333;
        position: relative;
      }
    }

    .ImagesTab__uploads--section {
      width: 100%;
      padding: 0.5rem;

      .VideosTab__upload-btn {
        width: 100%;
        margin: 10px auto;
      }
    }

    .Curtain {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .Curtain--loading {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .Curtain--hidden {
      display: none;
    }
  }
}

.Sidebar--expanded {
  .ImagesTab__heading--section {
    flex-direction: row;
  }
}