.VideoThumbnail {
  position: relative;
  padding-bottom: 56.25%; //16:9
  background-size: 50px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);

  .hover-component {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
  }

  &:hover {
    cursor: -webkit-grab;
    z-index: 1;

    .ProgressBar {
      width: 100%;
      transition: all .25s;
    }

    .VideoThumbnail__video {
      transition: all .25s;
      box-shadow: 0 0 0 1px var(--color1);
      border-radius: 3px;
      width: 100%;
      height: 100%;
    }

    .VideoThumbnail__controls {
      opacity: 1;
    }

    .VideoThumbnail__delete {
      opacity: 1;
      z-index: 2;
    }

  }

  &:active {
    cursor: -webkit-grabbing;
  }

  &__videoContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--color4);
  }
}

.VideoThumbnail__video {
  position: absolute;
  height: 100%;
  border-radius: .125rem;
}

.VideoThumbnail__controls {
  opacity: 0;
  position: absolute;
  padding: .25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2rem;
  right: 2rem;
  left: 2rem;
  bottom: 2rem;
  transition: opacity 0.25s ease;
}

.on {
  opacity: 1;
}

.VideoThumbnail__controls-content {
  display: flex;
  padding: .25rem;
  margin: .25rem;
  border-radius: .125rem;

  .VideoThumbnail__controls-fav {
    position: absolute;
    top: -30px;
    right: -30px;
    background: rgba(0, 0, 0, 0.2);
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 2px;
    opacity: 0.8;
    z-index: 1;

    &:hover {
      background: var(--color1);
      opacity: 1;
    }
  }

  .favorited {
    background: var(--color1);
  }

  .VideoThumbnail__controls-timestamp {
    background: rgba(0, 0, 0, 0.2);
    color: white;
    position: absolute;
    bottom: -20px;
    right: -20px;
    font-size: 13px;
    font-weight: 500;
  }
}

.VideoThumbnail__delete {
  opacity: 0;
  position: absolute;
  top: .25rem;
  right: .25rem;
  fill: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    background-color: #ff3805;
  }
}