.ContextSettingsLayerThumbnail {
  width: auto;
  height: 100%;
  margin: 50px auto 20px auto;
  border-radius: 2px;
}

.ContextSettingsGrid__Row .updateTimestamps {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
}

.ContextSettingsGrid__Column {
  flex-direction: column;
  display: flex;
  width: 33%;
}

.svgColorSwatch {
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  border: 4px solid #ccc;
}

.svgColorPicker {
  background: white;
  padding: 20px;
  justify-content: center;
  border-radius: 0 0 9px 9px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  position: relative;
  button {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    padding: 0 !important;

    svg {
      fill: #ccc;
      width: 20px;
      height: 20px;
    }
  }

  button.selected {
    background: #111;

    svg {
      fill: white;
    }
  }
}

.reset {
  text-align: right;
  padding: 5px 05px 1rem;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;
  transition: all 0.25s cubic-bezier(0.16, 1, 0.3, 1);
  color: gray;
  width: 90%;
  &:hover {
    transform: scale(1.02) translateY(-1px);
    color: black;
    background: default;
  }
  &:active {
    border: none;
  }
}
