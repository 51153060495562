@import '@styles/main.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&display=swap');

body {
  overflow: hidden !important;
}

.root__wrapper {
  height: 100%;

  .mobile {
    padding: 2rem;
    color: white;
    width: 100vw;
    font-size: 5rem;
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.App__payment--gateway {
  position: absolute;
  overflow: hidden;
  display: grid;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  align-items: center;
  background: rgba(34, 34, 40, 0.85);
}

.App__wrapper {
  transition: 0.3s all ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App__wrapper--toggle {
  transform: translateX(15%) scale(0.9);
}

.App__mid-container {
  display: flex;
  width: 100%;
  height: 0;
  justify-content: space-between;
  flex: 1;
  padding: 0 0.5rem;
  box-shadow: -8px 0px 4px -1px rgba(0, 0, 0, 0.1);
}

.notif__container {
  right: 1rem;
  top: 120px;
  left: auto;
}

.notif {
  padding: 0.8rem;
}

.notif--info {
  background-color: #209cee;
}

.notif--success {
  background-color: #2ebf91;
}

.notif--warning {
  background-color: #ffdd57;
}

.notif--danger {
  background-color: #ff3860;
}

[data-fabric-hiddentextarea] {
  position: fixed !important;
}

.upload-asset-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.__react_component_tooltip {
  background: var(--color5) !important;
  color: var(--color2) !important;
  border-color: var(--color3) !important;
}