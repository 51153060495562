.VideosTab {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .VideosTab__upload-btn {
    width: 100%;
    margin: 10px auto;
    min-height: 30px;
  }

  // heading section
  .VideosTab__heading--section {
    position: relative;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    background-color: var(--color5);
    // box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
    z-index: 5;

    .VideosTab__heading {
      color: #ffffff;
      font-size: 1rem;
      line-height: 1.75rem;
      font-weight: 500;
      margin-bottom: 5px;

      svg {
        margin-left: 15px;
        position: absolute;
        top: 17px;
      }
    }

    .VideosTab__heading--tos {
      // position: absolute;
      // right: 40px;
      // bottom: 20px;
      padding: 15px 0px 15px 10px;

      span {
        background: #c9c9c9;
        display: flex;
        padding: 2px;
        border-radius: 50%;

        svg {
          fill: black !important;
        }
      }

      .terms {
        position: fixed;
        width: 22rem;
        padding: 2rem;
        left: 21rem;
        background: white;
        border: 1px solid lightgrey;
        font-size: 14px;
        font-weight: 300;
        border-radius: 5px;

        h3 {
          font-size: 1.25rem;
          margin-bottom: 10px;
        }

        p {
          font-weight: 600;
        }

        ul {
          list-style-type: none;

          li {
            padding-top: 10px;
          }
        }
      }
    }
  }

  //tag section
  .VideosTab__Tag--section {
    .VideosTab__Categories {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        top: -5px;
      }

      .VideoTab__Categories__Item {
        width: 50%;
        color: black;
        font-size: 12px;
        cursor: pointer;
        padding: 5px 8px;
        background: white;

        &:hover {
          color: white;
          background: var(--color1);
        }
      }
    }

    .VideosTab__RelatedTags {
      margin-top: 0.5em;
      overflow: hidden;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
      }

      .VideosTab__RelatedTags__Item {
        width: auto;
        color: white;
        cursor: pointer;
        margin: 0 5px 5px 0px;
        padding: 1px 5px 3px 5px;
        background: #c9c9c9;
        border-radius: 5px;
        float: left;
        font-size: 12px;

        &:hover {
          background: var(--color1);
          color: white;
        }
      }
    }
  }

  // list section
  .VideosTab__List--section {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0.5rem;
    height: 100%;

    .Active-upload {
      padding-bottom: 56.25%;
      background-color: #333;
      position: relative;

      .ProgressBar {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .Curtain {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .Curtain--loading {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .Curtain--hidden {
      display: none;
    }

    .VideosTab__list {
      background-color: var(--color5);
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      align-content: flex-start;
      padding: 0.5rem;
    }
  }

  .VideoElements--expanded {
    color: white;
    display: grid;
    grid-column-gap: 5px;
    grid-template-columns: repeat(3, 5rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .VideosTab__no-results {
    color: #fff;
    padding: 0 0.25rem;
  }

  .VideosTab__section--uploads {
    position: relative;
    top: 0px;
    width: 100%;
    padding: 0.5rem;
  }
}

.Sidebar--expanded {
  .VideosTab__heading--section {
    flex-direction: row;
  }

  .VideosTab__Tag--section {
    padding-left: 20px;
  }
}