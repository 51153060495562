.SidebarContextOverlay {
  position: inherit !important;
}

.SidebarView__heading-wrapper {
  position: fixed;
  width: 350px;
  top: 80px;
  z-index: 10;
  background: #f2f2f2;
}

.ContextSettings__TextAnimations {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.ContextSettings__TextAnimations__Thumb {
  position: relative;
  width: 29%;
  height: 73px;
  margin: 5px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  font-size: 12px;

  .line {
    color: white;
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: var(--color4);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
  }
}

.ContextSettingsGrid__textStrokeControl {
  display: flex;
  align-items: center;
  grid-column-gap: 15px;

  .ContextSettingsGrid__Item--range {
    width: 280px;
    padding: 10px 10px;
  }
}

.AnimationThumbs {
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.ContextSettings__TextAnimations__Thumb--Selected {
  border: 2px solid var(--color1);
}

.ContextSettingsGrid__textRotationControl {
  display: flex;
  max-height: 5.25rem;
  margin-top: .352rem;
  width: 100%;

  button {
    width: 33%;
    justify-content: center;
  }
}

.ContextSettingsGrid__BorderContols {
  width: 100%;
  padding: 10px 20px;
}

.ContextSettingsGrid__FontColorPickers {
  position: relative;
  margin: 2px 10px 10px 0px;
  background: transparent;
}

.ContextSettingsGrid__ShadowColorPicker {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.positionControlsToggleButton {
  cursor: pointer;
  border-radius: 2px;

  path {
    fill: white;
  }
}

.positionControlsPopup {
  position: absolute;
  background: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  top: 100px;
  left: 250px;
  z-index: 9;
}

.lineheightDropdown {
  padding: 7px;
  position: relative;
  display: flex;
  align-items: center;
}

.lineheightControlsToggleButton {
  cursor: pointer;
  border-radius: 2px;

  svg {
    fill: white;
  }
}

.lineheightControlsPopup {
  position: absolute;
  background: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  top: 46px;
  left: -15px;
  width: 60px;
  height: 42px;
  padding: 10px;
  z-index: 1000;

  input {
    width: 40px;
    font-size: 15px;
  }
}

.SidebarTextContent .scroll-more {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 59px;
  z-index: 10;
  border-radius: 0;
  height: 28px;
  transition: 1s opacity ease;
  //background: linear-gradient(0deg, #ccc 0%, rgba(244, 244, 244, 0) 100%);
  box-shadow: none;
  pointer-events: none;
}

.textOptionsAndLayers {
  position: fixed;
  width: 350px;
  top: 130px;
  z-index: 11;
  background: #f2f2f2;

  .ButtonGroup {
    border: 1px solid #f3f3f3;
    width: 100%;
    margin: 10px;
    align-items: center;
  }
}

.styleOptions {
  background: var(--color5);
  height: 100%;
  overflow-y: scroll;

  .adjuster__close {
    position: absolute;
    right: 10px;
    font-size: 25px;
  }
}

.ContextSettingsGrid__Item--Copy {
  width: auto;
  margin-right: .375rem;
  background-color: white;
  border-radius: .2rem;
  border-color: transparent;
}

.ContextSettingsGrid__Item--Paste {
  width: auto;
  margin-right: .375rem 0;
  background-color: white;
  border-color: transparent;
  border-radius: .2rem;
}

.ContextSettingsGrid__Item--Apply {
  flex-grow: 1;
  background-color: var(--color1);
  padding-right: 0;
}

.ContextSettingsGrid__Item.layerItem {
  width: 100%;
  color: white;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #fff;
  background: #c7a90b;
  margin-bottom: 10px;
  padding: 0 10px;

  .items {
    display: flex;
    width: 100%;
  }

  .item.dragHandle {
    display: flex;
    width: 32px;

    svg {
      fill: white;
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .3));
    }
  }

  .item.title {
    display: flex;
    width: 100%;
    max-width: 150px;
    font-size: 10px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    display: flex;
    width: 64px;

    .Button svg {
      pointer-events: none;
      padding: 2px;
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .3));
    }
  }

  .actions .Button.visible {
    padding: 0;
    margin-right: 10px;
  }

  .actions .Button.remove {
    padding: 0;
  }
}

.ContextSettingsGrid__Item.layerItem.selected {
  border-color: #fff;
  background: #888;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
}

.layerOptions .ContextSettingsGrid__Item button svg {
  fill: white !important;
}

.layerOptions .layerButton {
  width: 100%;
  justify-content: left;
  background: transparent;
  border-color: transparent;

  button {
    margin: 0 5px;
  }
}

.animationOptions {
  width: 100%;

  .ButtonGroup {
    margin-bottom: 20px;
  }

  .ButtonGroup__item {
    width: 100%;
  }
}

span.number {
  // font-family: "Quicksand", sans-serif;
  background: black;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 13px;
  color: white;
  text-align: center;
}

.animationOrderPopup {
  position: relative;
  width: 340px;
  height: auto;
  z-index: 1;
  background: #111d81;
  padding: 20px;
  border: 2px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  overflow: scroll;

  &:after {
    content: ' ';
    width: 10px;
    height: 10px;
    background: #111d81;
    position: absolute;
    top: -5px;
    right: calc(50% - 10px);
    transform: rotate(45deg);
  }

  span.ContextSettingsGrid__header {
    color: white !important;
    font-size: 13px;
    margin-bottom: 20px;
  }


  .animationOptions__layers {
    overflow: scroll;
    padding: .25rem;
  }
}