.SingleProject {
  height: 100%;
  width: 100%;
}

.SingleProject__section {
  padding: 0.75rem;
  padding-left: 0;
}


.VideosTab__heading--section {
  position: relative;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  background-color: var(--color5);
  // box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
}

.SingleProject__back-button {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: flex;
  cursor: pointer;

  svg {
    margin-right: 0.25rem;
  }
}

.ContextSettingsGrid__Row {
  h5 {
    padding: 9px 9px 0 0;
  }

  .SingleProject__name-input {
    appearance: none;
    border: none;
    background: none;
    outline: none;
    color: #111;
    position: relative;
    padding: 0 0 0 12px;
    border-radius: 5px;
    height: 35px;

    &:focus,
    &:active {
      background-color: white;
      border-bottom: 1px solid #ccc;
    }

    &:hover {
      background-color: white;
    }
  }
}

.SingleProject__button {
  width: 100%;
  text-align: left;
}

.Sidebar--expanded {
  .SingleProject__button {
    max-width: 40%;
    text-align: left;
  }

  .project-versions-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
  }

  .Video {
    height: 60px;
  }
}