.Trash {
  position: absolute;
  width: 100vw;
  height: 100%;
  opacity: 0.8;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c9c9c9;
  opacity: 0;
  svg {
    position: absolute;
    left: 40%;
    fill: darkred;
    transform: scale(7);
  }
}

.Trash--active {
  position: absolute!important;
  width: 100vw;
  top: 78vh !important;
  height: 20vh !important;
  pointer-events: auto;
  opacity: 0.8;
  // border-right: 3px dashed darkred;
  // border-left: 3px dashed darkred;
  svg {
    position: absolute;
    left: 50%;
    fill: darkred;
    transform: scale(10);
  }
  .cover {
    transform: rotateZ(0) translateY(0) translateX(0);
  }
}
