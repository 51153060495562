.ProgressBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  left: 0;
}

.ProgressBar__progress {
  height: 100%;
  background-color: var(--color1);
  border-radius: 100px;
}