.Modal {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 99999;
  align-items: center;
  justify-content: center;
}

.Modal__backdrop {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 10;
  background-color: rgba(0,0,0,0.3);
  transition: all 225ms;
}
.Modal__container {
  position: relative;
  min-width: 400px;
  max-width: calc(100vw - 2rem);
  color: #fff;
  padding: 1rem 1rem 1.5rem 1rem;
  max-width: 600px;
  max-height: calc(100% - 48px);
  position: absolute;
  z-index: 10;
  transition: all 225ms;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  border-radius: 5px;
  background: #0f0c29;
  background: -webkit-linear-gradient(to top right, #24243e, #302b63, #0f0c29);
  background: linear-gradient(to top right, #24243e, #302b63, #0f0c29);
}

.Modal__top {
  display: flex;
  align-items: center;
}

.Modal__title {
  font-size: 18px;
  display: flex;
  flex-grow: 1;
}

.Modal__content {
  padding-top: 1rem;
}
.Modal__close {
  cursor: pointer;
  font-size: 2rem;
}

.Modal__label {
  font-size: 14px;
  display: block;
  padding-bottom: .5rem;
}

.Modal__input-wrapper {
  padding-bottom: 1rem;
}
