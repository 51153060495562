.ContextSettings__Adjuster {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  //height: 38px;
  color: white;
  font-weight: 500;
  padding: 4px 0;

  .details {
    display: flex;
    margin: 0px -3px;
    padding: 10px 0px;
    color: var(--color2);
  }

  .palette {
    color: var(--color2);
    font-size: 12px;
    font-weight: 600;

    p {
      margin: 12px 0px;
    }
  }

  .thumbnail {
    display: flex;
    margin: .1rem;
    height: 1.5rem;
    width: 1.5rem;
    // border: 4px solid #ccc;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    background-color: #c9c9c9;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    line-height: 1.25rem;
    justify-content: center;
    pointer-events: none;
  }

  .name {
    border: none;
    background: none;
    outline: none;
    background-color: transparent;
    border-radius: .125rem;
    line-height: 2rem;
    font-size: 16px;
    font-weight: 600;
    color: var(--color2);
    padding-left: .5rem;
    padding-right: .5rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
  }
}

.hidden {
  display: none;
}

.Adjustable__default {
  margin-bottom: 20px;

  &__title {
    color: #111;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
  }
}

.ContextSettings__Adjuster.selected {
  background-color: white;
}

.adjustableOptions {
  position: relative;
  width: 100%;
  border-radius: .3em;
  max-width: 335px;
  padding: 12px;
  width: 100%;
  margin: 0;
  border: none;
}


.adjustableArrow {
  display: none;
  position: relative;
  width: 15px;
  height: 15px;
  background-color: white;
  top: 8px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
  left: 150px;
  z-index: 50;
  transform: rotate(45deg)
}

.Adjuster__header {
  display: flex;
  width: 100%;

  h2 {
    color: #c3ced7;
    font-size: 15px;
    font-weight: 600;
    margin-right: 15px;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.Adjuster__content {
  margin-top: 15px;
}

.Adjuster__minimize {
  pointer-events: none;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;

  span {
    pointer-events: none;
  }

  svg {
    pointer-events: none;
    fill: var(--color2);
    transform: rotate(90deg);
  }
}

.up {
  svg {
    transform: rotate(270deg);
  }
}

div[title="#ffffff"] {
  border: 1px solid transparent;
}

div[title="#000000"] {
  border: 1px solid #fff;
}

div[title="transparent"] {
  background-color: white;
}

.circle-picker {
  justify-content: flex-start;
}

.alphaPickerContainer {
  width: 250px;
  padding: 20px 5px;
  border-top: 1px solid #fff;
  //margin: 20px -10px -20px -10px;
}

.actionButton {
  background: var(--color1);
  width: 100%;
  margin: 15px auto 0 auto;
  text-align: center;
  justify-content: center;
}

.rc-slider-rail {
  background-color: var(--color1) !important;
}

.rc-slider.sliderLabel {
  font-size: 12px;
  font-weight: 600;
  height: auto;
}

.rc-slider.sliderLabel.shadow:before {
  display: inline-block;
  color: #c3ced7;
  text-transform: capitalize;
  margin: 15px 0;
}

.rc-slider.sliderLabel.shadow.offsetX:before {
  content: "offset X";
}

.rc-slider.sliderLabel.shadow.offsetY:before {
  content: "offset Y";
}

.rc-slider.sliderLabel.shadow.blur:before {
  content: "Blur";
}

.presetButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    color: #111;
    background: white;
    margin: 0 3px;
  }
}