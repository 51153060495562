.TimeTooltip {
  top: -35px;
  background-color: var(--color5);
  color: var(--color2);
  padding: 2px 5px;
  border-radius: 2px;
  position: absolute;
  right: 0;
  font-size: 14px;
  user-select: none;
  z-index: 100;
  // &:after {
  //   content: ' ';
  //   position: absolute;
  //   background: white;
  //   width: 2px;
  //   z-index: 2;
  //   right: 0px;
  //   top: -2px;
  //   height: 24px;
  // }
}