.Video {
  padding: 0.375rem;
  background-color: rgba(255, 255, 255, 0.025);
  border-radius: 0.125rem;
  color: #fff;
  font-weight: 500;
  display: flex;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
}
.Sidebar--expanded {
  .Video {
    width: 50%;
  }
}
.Video__details {
  display: flex;
  flex: 1;
  cursor: pointer;
}

.Video--active {
  // background-color: rgba(255,255,255, 0.1);
  background-color: #f3f3f3;
}

.Video__thumbnail-container {
  height: 3rem;
  width: 3rem;
  margin: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Video__thumbnail {
  background: black;
  border-radius: 0.125rem;
  background-size: cover;
}

.Video__thumbnail--vertical {
  width: 60%;
  height: 100%;
}

.Video__thumbnail--square {
  width: 60%;
  height: 60%;
}

.Video__thumbnail--horizontal {
  width: 100%;
  height: 60%;
}
.Video__thumbnail--facebook_vertical {
  width: 60%;
  height: 70.3%;
}

.Video__thumbnail-image {
  width: 100%;
  height: 100%;
}

.Video__content {
  flex: 1;
  margin: 0.375rem;
  display: flex;
  flex-direction: column;
  line-height: 1.25rem;
  justify-content: center;
}

.Video__duration {
  font-size: 0.875rem;
  color: #eee;
  line-height: 1.25rem;
  font-weight: 500;
}

.Video__name-input {
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 0.125rem;
  line-height: 2rem;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  padding-left: 0.5rem;
  max-width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.Input-container {
  position: relative;
}

.Input-container--loading {
  &:after {
    animation: spinAround 0.5s infinite linear;
    border: 2px solid #dbdbdb;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 0.75em;
    width: 0.75em;
    position: absolute;
    right: 0.3em;
    top: 50%;
    margin-top: -0.5em;
  }
}
