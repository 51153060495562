.AspectRatioOption__option {
  color: #48798c;
  font-size: .7rem;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  .visual {
    transition: all 0.25s ease-in-out;
    border: 2px solid #48798c;
    background-image: url("https://images.unsplash.com/photo-1496275068113-fff8c90750d1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80");
    background-size: cover;
    background-position: center;
    opacity: .5;
    filter: grayscale(50%);
  }

  &:hover {
    color: white;
    ;

    .visual {
      border: 2px solid white;
      opacity: 1;
      filter: grayscale(0%);
    }
  }

  &.selected {
    color: white;

    .visual {
      border: 2px solid var(--color1);
      opacity: 1;
      filter: grayscale(0%);
    }
  }

  &.square {
    .visual {
      width: 60%;
      padding-top: 60%;
      margin: 0 auto;
    }
  }

  &.vertical {
    .visual {
      width: 60%;
      padding-top: 100%;
      margin: 0 auto;
    }
  }

  &.horizontal {
    .visual {
      padding-top: 60%;
      width: 100%;
      margin: 0 auto;
    }
  }

  &.facebook_vertical {
    .visual {
      width: 60%;
      padding-top: 70.3%;
      margin: 0 auto;
    }
  }

}

.AspectRatioOption__content {
  width: 100%;
}

.AspectRatioOption__details {
  padding-top: .5rem;
}

.AspectRatioOption__label {
  text-align: center;
  font-size: .8rem;
  font-weight: bold;
}

.AspectRatioOption__description {
  margin: 2px;
  text-align: center;
  font-size: .7rem;
}