.TextTab {
  height: 100%;

  &__list {
    height: 90% !important;
  }
}

.DefaultTexts {
  border-radius: .2em;
  background-color: var(--color4);
  color: var(--color2);
  border: none;
  margin-bottom: 7px;
  height: 40px;
  padding: 10px 10px 10px 10px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    background-color: var(--color1);
    color: white;
  }
}

.TextTab__addheading {
  padding-top: 5px;
}

.DraggableFootage {
  height: 90px;
  visibility: visible;

  &--isDragging {
    visibility: hidden;
  }
}

.TextTab__delete {
  position: absolute;
  transform: scale(12);
  z-index: 2;
  right: 8rem;
  top: 14rem;

  svg {
    fill: #a2a2a2;
    width: 15px;
    height: 15px;

    &:hover {
      fill: darkred;
    }
  }
}

.TextTab__section {
  position: relative;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  // box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
  z-index: 5;
}

.TextTab__heading {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
  flex: 1 1 0%;
}

.TextTab__add-text-btn {
  width: 100%;
}

.TextTab__style-thumbnail {
  position: relative;
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  color: white;
  background: #888;
  border: 1px solid #d6d6d9;
  border-radius: 0.125rem;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
}

.Sidebar--expanded .TextTab__style-thumbnail {
  transform: scale(0.157) !important;
}

.Sidebar--expanded .TextTab .DraggableFootage {
  height: 170px;
}

.TextTab .scroll-more {
  position: absolute;
  left: 0px;
  right: 6px;
  bottom: 0px;
  border-radius: 0;
  height: 28px;
  transition: 1s opacity ease;
  background: linear-gradient(0deg, #ccc 0%, rgba(244, 244, 244, 0) 100%);
  box-shadow: none;
  pointer-events: none;
}