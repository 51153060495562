.MusicTab {
  height: 100%;
}

.MusicTab__heading {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
  flex: 1 1 0%;
}

.MusicTab__heading-wrapper {
  display: flex;
}

.MusicTab__heading__activeTrack {
  padding: 10px;

  .TrackRow.TrackRow--active {
    margin-top: 10px;
  }

  span {
    color: white;
  }

  .TrackRow__button-wrapper {
    button:nth-child(1) {}
  }
}

.MusicTab__section--full {
  padding: .75rem;
  display: flex;
  flex-direction: column;
}

.MusicTab__list {
  display: flex;
  flex-direction: column;
  height: 78% !important;
  padding: 0.75rem;
  gap: 1rem;
}

.Sidebar--expanded {
  .MusicTab__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .TrackRow {
    font-size: 12px;
    width: 50%;
    height: 60px;
  }
}

.MusicTab .scroll-more {
  position: absolute;
  left: 0px;
  right: 6px;
  bottom: 0px;
  border-radius: 0;
  height: 28px;
  transition: 1s opacity ease;
  //background: linear-gradient(0deg, #ccc 0%, rgba(244, 244, 244, 0) 100%);
  box-shadow: none;
  pointer-events: none;
}