.FullscreenLoader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(720deg);
  }
}

.FullscreenLoader__logo {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.FullscreenLoader__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    color: var(--color7);
    font-weight: bold;
    font-size: 16px
  }

  .spinner {
    margin-top: 20px;
    width: 24px;
    height: 24px;
    animation: rotate 0.9s infinite linear;
    transform-origin: center center !important;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}