.VideoActionsMenu__active {
  width: 100px;
  background-color: #f3f3f3;
  position: absolute;
  top: 59px;
  right: -11px;
  min-height: 3rem;
  z-index: 1;
  box-shadow: -3px 3px 4px rgba(0, 0, 0, .15);
  border-bottom-left-radius: 10px;
}

.VideoActionsMenu {
  width: 100px;
  background-color: white;
  position: absolute;
  top: 59px;
  right: -11px;
  min-height: 3rem;
  z-index: 1;
  box-shadow: -3px 3px 4px rgba(0, 0, 0, .15);
  border-bottom-left-radius: 10px;
}

.VideoActionsMenu__option {
  padding: 12px;
  width: 100%;
  font-size: 12px;
  text-align: right;
  color: #111;

  &:hover {
    background-color: var(--color1);
    color: white;
    cursor: pointer;
  }

  &:not(:first-child) {
    border-top: 1px solid #f3f3f3;
  }

}