.GenerateTab {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Modal {
  .text {
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-size: 12px;
    // background: rgba(27, 22, 62, 0.23);
    left: 0;
    font-weight: 600;
  }
}

.GenerateTab__logos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1rem;
  grid-column-gap: 0.5rem;
  padding: 1rem;
  background: #120e2f;
  max-height: 50vh;
  overflow-y: scroll;
  border-radius: 12px;

  .ImagesTab__uploads--section {
    grid-column-start: 1;
    grid-column-end: 3;
    position: absolute;
    top: 4rem;
  }

  .Spinner {
    margin: 0 auto;
  }
}

.GenerateTab__section {
  margin-bottom: 1px;
  padding: 0 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;

  input {
    padding: 0.5rem;
    width: 100%;
    // font-weight: 600;
    border-radius: 0.2em;
    border: 1px solid #f3f3f3;
  }

  .ButtonGroup {
    border-radius: 10rem;
  }
}

.GenerateTab__Optional {
  width: 100%;
  font-size: 14px;
  padding-top: 5px;
  font-weight: 600;
}

.GenerateTab__logo-upload {
  height: 4rem;
  width: 4rem;
  position: relative;
  background: #c9c9c9;
  border-radius: 0.2em;
  cursor: pointer;

  .delete {
    position: absolute;
    right: -25px;

    svg {
      height: 15px;
      fill: #898989;
    }
  }

  .add {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  .GenerateTab__choosenlogo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.GenerateTab--beta-rivon {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 5rem;
  overflow: hidden;
}

.GenerateTab--beta-rivon p {
  box-sizing: border-box;
  background-color: #2c207f;
  font-weight: 600;
  color: white;
  transform: rotate(50deg);
  position: absolute;
  top: 1rem;
  padding: 0.25rem 2rem;
  right: -27px;
  opacity: 0.45;
}

.Sidebar--expanded {
  .GenerateTab__section {
    width: 50%;
  }
}

.Keywords-Section {
  padding: 0em 0.75rem;

  .add-keywords {
    width: 90%;
  }
}

.GenerateTab__section--full {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
}

.GenerateTab__heading {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
  flex: 1 1 0%;
}

.input--add-keywords {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  padding-left: 0.5rem !important;
}

.GenerateTab__subheading {
  display: flex;
  flex: 1;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.1rem;
  align-items: center;
  margin: 12px 0px 4px 0px;
}

.GenerateTab__keywords-added {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.125rem;
}

.GenerateTab__keyword-suggestions {
  display: flex;
  flex-wrap: wrap;
}

.GenerateTab__generate-btn {
  // margin: 0 auto;
  width: 100%;
  justify-content: space-between;
}

.GenerateTab__RelatedKeywords {
  margin-top: 10px;

  ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
    display: flex;
    flex-wrap: wrap;
  }
}

.GenerateTab__RelatedKeywords span {
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.GenerateTab__RelatedKeywords__Item {
  color: #fff;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  font-size: 0.65rem;
  line-height: 1.5rem;
  border-radius: 0.2rem;
  padding: 0px 6px;
  margin: 0px 5px 5px 0px;
  background-color: rgba(255, 255, 255, 0.1);
}