.ImageThumbnail {
  position: relative;
  padding-bottom: 56.25%;
  height: 100%;

  &:hover {
    cursor: -webkit-grab;

    .ImageThumbnail__image {
      box-shadow: 0 0 0 0.125rem var(--color1);
    }

    .ImageThumbnail__controls {
      opacity: 1;
    }

    .ImageThumbnail__delete {
      opacity: 1;
    }
  }

  &:active {
    cursor: -webkit-grabbing;
  }
}

.ImageThumbnail__image {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.125rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.ImageThumbnail__controls {
  position: absolute;
  padding: 0.25rem;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  top: 2rem;
  right: 2rem;
  left: 2rem;
  bottom: 2rem;
  transition: opacity 0.25s ease;

  .ImageThumbnail__favorite-icon {
    position: absolute;
    top: -30px;
    right: -30px;
    background: rgba(0, 0, 0, 0.2);
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 2px;
    opacity: 0.8;

    &:hover {
      background: var(--color1);
      opacity: 1;
    }
  }

  .isFavorited {
    background: var(--color1);
    opacity: 1;
  }
}

.ImageThumbnail__controls-content {
  display: flex;
  padding: 0.25rem;
  margin: 0.25rem;
  border-radius: 0.125rem;
}

.ImageThumbnail__delete {
  opacity: 0;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  fill: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    background-color: #ff3805;
  }
}