.Button {
  display: flex;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border: none;
  background: none;
  border-radius: .125rem;
  padding: .5rem .75rem;
  font-size: 12px;
  line-height: 1.5rem;
  font-weight: 600;
  position: relative;
  color: #ffffff;
  overflow: hidden;
  border-radius: 3px;
  padding: .2rem .6rem .2rem .6rem;
  font-size: .8rem;

  &:after {
    position: absolute;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

.Button__loading {
  color: transparent !important;
  pointer-events: none;

  &:after {
    animation: spinAround .5s infinite linear;
    border: 2px solid #dbdbdb;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1em;
    width: 1em;
  }
}

.Button--full {
  width: 100%;
}

.Button--orange {
  background: rgb(255, 90, 5);
}

.Button--red {
  background: #ff3805;
}

.Button--purple {
  background: #111d81;
}

.Button--pink {
  background: var(--color1);
  box-shadow: 0px 1px 3px rgba(128, 128, 128, 0.35);
  border-radius: 3px;
  padding: .2rem .6rem .2rem .6rem;
  font-size: .8rem;
}

.Button--white {
  background-color: rgba(255, 255, 255, 1);
  color: #07f;
}

.Button--icon-only {
  padding: .5rem;
}

.Button--with-icon {
  justify-content: space-between;

  svg {
    margin-left: .4rem;
  }
}

.Button--light-bg {
  background-color: rgba(255, 255, 255, 0.05);
}

.Button--playing {
  background-color: #c62e2d !important;
  border-radius: 50% !important;

  svg: {
    fill: white !important;
  }
}

.Button--light-bg:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.Button--disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.Button--copy {
  color: #111;
}

.Button--paste {
  color: #111;
}