.SidebarView {
  display: flex;
  flex-wrap: wrap;
}

.SidebarView__heading-wrapper {
  display: flex;
  padding: .75rem;
}

.SidebarView__heading {
  color: #ffffff;
  font-size: 1em;
  line-height: 1.2rem;
  font-weight: 500;
  flex: 1 1 0%;
  margin-right: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SidebarView__section {
  width: 100%;
}

.SidebarView__close-wrapper {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.SidebarView__close {
  margin-left: 10px;
  color: #111;
  display: block;
  border-radius: 50%;
  text-align: center;
  width: 1rem;
  height: 1rem;
  background: white;
  cursor: pointer;

  &:hover {
    background-color: var(--color1);
    color: white;
  }

  span {
    position: relative;
    font-size: .9em;
    font-weight: 600;
    top: -3px;
  }
}