.MoveDialog__label {
  font-size: 14px;
  display: block;
  padding-bottom: .5rem;
}

.MoveDialog__button-container {
  display: flex;
  padding-top: 1rem;
  justify-content: flex-end;
  button {
    margin-left: 5px;
  }
}
