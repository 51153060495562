.SidebarContextOverlay {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
  flex-direction: column;
  background-color: white;
}

.ContextMenu__header {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 5px;
}

.ContextSettingsGrid {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  width: 100%;

  &--TextStyles {
    background: white;
  }
}

.ContextSettingsGrid__Section {
  margin: 10px 0px;
  border: 1px solid #c9c9c9;
  border-width: 0px 0px 1px 0px;

  &:first-of-type,
  &:last-of-type {
    border: none;
  }
}

.ContextSettingsGrid__Row {
  color: var(--color2);
  // gap: 1rem;
  padding: 0px 2px;
  display: flex;
  width: 100%;
  margin-bottom: 5px;

  .position {
    margin-right: 2.5rem;
  }
}

.Tabs {
  padding: 0;
  height: 100% !important;

  .position {
    margin-right: 2.5rem;
  }
}

.ContextSettingsGrid__Header {
  font-size: 14px;
  font-weight: 600;
}

.ContextSettingsTransformIcons {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
}

.ContextSettingsStickyBottomBar {
  position: absolute;
  bottom: 0;
  // left: 0px;
  // width: 352px;
  // display: flex;
  // padding: 5px 0;
  // background: #433e4d;
  // border-top: 1px solid #2a2538;
  // justify-content: space-between;
  // z-index: 9;
  width: 100%;
}

.ContextSettingsStickyBottomBar__up {
  bottom: 184px;
}

.ContextSettingsStickyBottomBar__Item {
  padding: 10px;
}

.ContextSettingsGrid__Row--actions {
  border-top: 1px solid rgba(255, 255, 255, 0.025);
  padding: .5rem;
  margin-top: 1rem;
  justify-content: flex-end;
}

.ContextSettingsGrid__Item {
  // margin: .375rem;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  font-weight: 600;
  display: flex;

  span {
    font-size: 17px;
    color: #111;
  }
}

.ContextSettingsGrid__header {
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 600;
}

.ContextSettings__inOutInputs {
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 400;
  margin: 3px 15px -2px 0px;
}

.ContextSettings__Select-Box {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  outline: none;

  margin: .375rem;
  padding: .375rem;
  border-radius: .125rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  background-color: rgba(255, 255, 255, 0.025);
  border: 1px solid rgba(255, 255, 255, 0.025);
}

.ContextSettings__scaleOptions-select {
  margin: .375rem;
  flex: 1;
}

.ContextSettings__scaleOptions-select--toolbar {
  flex: 1;
  margin: .375rem;
  font-size: 12px;
}

.ContextSettings__FontOptions-select {
  background-color: var(--color5);
  font-size: 12px;
  font-weight: 400;
  flex: 1;
}

.ContextSettings__FontOptions-option__single-value {
  color: var(--color2) !important;
}

.ContextSettings__FontOptions-select--toolbar {
  flex: 1;
  font-size: 12px;
  font-weight: 400;
}

.ContextSettings__FontOptions-option__control {
  background-color: var(--color4) !important;
}

.ContextSettings__modifier-btn {
  padding: 0;
}

.Button-Selector {
  margin: .375rem;
  display: flex;
  border: .5px solid rgba(255, 255, 255, 0.025);
}

.Button-Selector__Item {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  background-color: rgba(255, 255, 255, 0.025);
}

.Button-Selector__Item--Selected {
  background-color: var(--color1);
}

.Button-Selector__Item svg {
  fill: #111;
}

.Button-Selector__Item--Selected svg {
  fill: rgba(255, 255, 255, 1);
}

.ContextSettingsGrid__Item--Selected span {
  color: white;
}

.ColorSelector {
  display: flex;
  flex: 1;
  margin: .375rem;
  padding: .375rem;
  background-color: rgba(255, 255, 255, 0.025);
  border: 1px solid rgba(255, 255, 255, 0.025);
  padding-left: .75rem;
  padding-right: .75rem;
}

.ColorSelector__Label {
  font-size: .875rem;
  line-height: 1.5rem;
  color: #fff;
  font-weight: 500;
  flex: 1;
}

.StrokePicker {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: .25rem solid pink;
}

.BgPicker {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: .125rem;
  background-color: pink;
}

// input[type="color"] {
// 	-webkit-appearance: none;
// 	border: none;
// 	width: 24px;
// 	height: 24px;
//   border: 1px solid #eeeeee;
//   border-radius: .125rem;
// }
// input[type="color"]::-webkit-color-swatch-wrapper {
// 	padding: 0;
// }
// input[type="color"]::-webkit-color-swatch {
// 	border: none;
// }

.ContextSettingsGrid__Item--Flex-1 {
  padding-left: .75rem;
  padding-right: .75rem;
  flex: 1 1 0%;
}

.ContextSettingsGrid__Item--1-3 {
  width: 33.333%;
  padding-left: .75rem;
  padding-right: .75rem;

}

.ContextSettingsGrid__Item--Small {
  width: 3rem;
  padding-left: .75rem;
  padding-right: .75rem;

}

.ContextSettingsGrid__Item--Delete {
  padding-left: .75rem;
  padding-right: .75rem;
  flex: 1 1 0%;
  background-color: transparent;
  border: none;
}

.ContextSettingsGrid__Item--Apply {
  justify-content: center;
  border: none;
  border-radius: .2em;
}

.ContextSettingsGrid__Item--Selected {
  background-color: rgba(255, 255, 255, 0.2);

}

.ContextSettingsGrid__Item svg {
  fill: rgba(255, 255, 255, 0.5);
}

.ContextSettingsGrid__Item--Selected svg {
  fill: rgba(255, 255, 255, 1);
}


.ContextSettings__Block {
  display: flex;
}

.ModifierSelect {
  width: 8rem;
  margin: .25rem;
}

.ContextSettings__Block--Full {
  width: 100%;
}

.ContextSettings__Block--Half {
  width: 50%;
}

.ContextSettings__Block-Label {
  font-size: .875rem;
  line-height: 21px;
  color: #ffffff;
  flex: 1;
  margin: .75rem;
  font-weight: 500;
}

.Block-Border-Right {
  border-right: 1px solid rgba(255, 255, 255, 0.075);
}

.Block-Border-Top {
  border-top: 1px solid rgba(255, 255, 255, 0.075);
}

.Block-Border-Bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.075);
}

.PositionSelector {
  display: flex;
  flex-wrap: wrap;
  margin: .375rem;
  width: 4.25rem;
}

.PositionSelectorItem {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: .125rem;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 1px;
}

.PositionSelectorItem:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.PositionSelectorItem--Selected {
  background-color: rgba(255, 255, 255, 0.9);

}

.rc-slider-track {
  background: var(--color1) !important;
}

.rc-slider-handle {
  border: solid 2px var(--color1) !important;
}