.browserInfo {
  padding: 2rem;
  background: #123;
  color: white;
  z-index: 9999;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100vw;
}
