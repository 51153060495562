.overlay-resizer {
  position: absolute !important;
  top: 5px;
  height: 36px;
  box-shadow: 0 0 0 0.205rem var(--color1);
  border-radius: 0.125rem;
  overflow: hidden;
}

.overlay-draggable {
  position: absolute;
  top: 3px;
  z-index: 1;
  height: 43px;
  margin-left: 11px;
  cursor: ew-resize;
  background-color: transparent;
  background-repeat-y: no-repeat;
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
}