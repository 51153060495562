.ShapesTab {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .ShapesTab__heading--section {
    padding: 0.75rem;
    display: flex;
    flex-direction: column;

    .ShapesTab__heading {
      color: #ffffff;
      font-size: 1rem;
      line-height: 1.75rem;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  .ShapeThumbnail {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: .125rem;
    background: var(--color4);
    padding: .5rem;

    &:hover {
      box-shadow: 0 0 0 0.125rem var(--color1);
    }

    img {
      height: 100%;
    }
  }

  .ShapesTab__list--section {
    // padding-bottom: 140px;
    height: 100%;

    .ShapesTab__list {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      align-content: flex-start;
      padding: 0.5rem;

      .Active-upload {
        padding-bottom: 56.25%;
        background-color: #333;
        position: relative;
        margin: .2rem;
      }
    }

    .ShapesTab__uploads--section {
      width: 100%;
      padding: 0.5rem;

      .VideosTab__upload-btn {
        width: 100%;
        margin: 10px auto;
      }
    }

    .Curtain {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
    }

    .Curtain--loading {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .Curtain--hidden {
      display: none;
    }
  }
}

.Sidebar--expanded {
  .ShapesTab__heading--section {
    flex-direction: row;
  }

  .ShapesTab .ShapeThumbnail {
    width: 12.5%;
  }
}