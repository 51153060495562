.ProjectsTab {
  position: relative;
  height: 100%;
  width: 100%;
}

.scroll-y {
  width: 100%;
}

.ProjectsTab__top-container {
  padding: 0.75rem;
}

.ProjectsTab__heading {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
  flex: 1 1 0%;
}

.ProjectsTab__new-project-btn {
  width: 100% !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e0949;

  svg {
    fill: var(--color1);
    height: auto;
    width: 20%;
  }
}

.Sidebar--expanded {
  .ProjectsTab__new-project-btn {
    max-width: 40%;
  }
}

.ProjectsTab__button {
  .ProjectCard {
    box-shadow: none;
    border: 1px solid #e9e9e9;

    &:hover {
      border: 1px solid var(--color1);
      border-radius: 0.3rem;
    }
  }
}

.Curtain {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Curtain--loading {
  background-color: rgba(0, 0, 0, 0.5);
}

.Curtain--hidden {
  display: none;
}

.ProjectsTab .scroll-more {
  position: absolute;
  left: 0px;
  right: 6px;
  bottom: -1px;
  border-radius: 0;
  height: 28px;
  transition: 1s opacity ease;
  //background: linear-gradient(0deg, #ccc 0%, rgba(244, 244, 244, 0) 100%);
  box-shadow: none;
  pointer-events: none;
}