input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputNumber {
  position: relative;

  input {
    position: relative;
    top: -6px;
    width: 35px;
    padding: 5px 0px;
    margin: 0px 4px;
    font-size: 1.1em;
    font-weight: 800;
    background-color: rgba(0, 0, 0, 0);
    border-width: 0px 0px 0px 0px;
    border-radius: .2em;
    text-align: left;

    &:focus {
      position: relative;
      top: 4px;
      font-weight: 800;
      text-align: left;
      outline-width: 0px;
      width: 35px;
      background-color: #c62e2d;
      color: #111;
      border: 1px solid #c9c9c9;
      border-width: 0px 0px 1px 0px;
    }
  }
}

.minuteConversion {
  position: relative;
  font-size: 10px;
  background-color: white;
  top: -2px;
  right: -15px;
  border-radius: 5px;
  text-align: center;
  padding: 3px;
}