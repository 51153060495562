.Toolbar__Text {
  height: 100%;
  width: 100%;
  display: flex;

  .ToolbarGroup {
    .ToolbarThumbnail {
      &__TypeText {
        width: 2.25rem;
        background-color: #c62e2d;
        height: 100%;

        svg {
          fill: white;
          width: 100%;
          height: 100%;
          transform: scale(0.5);
        }
      }
    }

    // toolbar dropdown font style and size
    .ToolbarItem--dropDown {
      display: grid;
      grid-template-columns: 10rem 5rem;
      width: 15.5rem;
      grid-gap: 0.25rem;
      padding-left: 0.25rem;
      // border: 1px solid lime;
    }
  }

  .Toolbar__right {
    .ToolbarItem {
      // border-right: 1px solid #f4f4f4;
      text-align: center;
    }
  }

  button {
    color: black;
    font-weight: 500;
    font-size: 12px !important;
    box-sizing: border-box;
  }
}