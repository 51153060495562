.Navigation {
  display: flex;
  background-color: white;
  border-radius: .2em;
}

.Navigation__tabs {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  border-radius: .2em;
}

.Navigation__Item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0px 3px;

  span {
    color: #111;
    font-size: 16px;
  }

  .borderIcon {
    font-size: 12px;
    position: absolute;
    top: 6px;
    right: 12px;
  }

  .fontColor {
    position: relative;
    top: -4px;
    border: .5px solid black;
  }

  button {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    height: 100%;

    span {
      margin: 0;
    }
  }
}

.active {
  background-color: var(--color1);
  border-radius: 5px;

  svg {
    fill: white;
  }

  span {
    color: white;
  }
}

.icon {
  width: 32px;
}

.Navigation__Item--Apply {
  flex-wrap: nowrap;
  display: flex;
  background-color: var(--color1);
  justify-content: center;
  border-radius: .2em;
  width: 120px;
}