.Ruler {
  width: 100%;
  display: flex;
}

.Ruler__division {
  width: 25%;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
}

.Ruler__division:first-child {
  border-left: none;
}

.Ruler__division:last-child {
  justify-content: flex-end;
}


.Ruler__time {
  font-size: .75rem;
  line-height: .875rem;
  font-weight: 600;
  padding: 2px 5px;
}

.Ruler__time--total {
  color: var(--color2);
  transition: all .25s;

  &.isActive {
    color: black;
    background-color: white;
    border-radius: 2px;
  }
}

.Ruler__time--played {
  color: var(--color7);
}